export enum Languages {
  English = 'en',
  German = 'de',
  Spanish = 'es',
  French = 'fr',
  Italian = 'it',
  Russian = 'ru',
  Japanese = 'jp',
  ChineseSimplified = 'zs',
  ChineseTraditional = 'zt',
  Korean = 'kr',
  Czech = 'cz',
  Portuguese = 'pt',
  Thai = 'th',
}

export const getLanguagesWithLabel = [
  { code: Languages.German, label: 'Deutsch' },
  { code: Languages.English, label: 'English' },
  { code: Languages.Spanish, label: 'Español' },
  { code: Languages.French, label: 'Français' },
  { code: Languages.Italian, label: 'Italiano' },
  { code: Languages.Russian, label: 'русский' },
  { code: Languages.Japanese, label: '日本語' },
  { code: Languages.ChineseSimplified, label: '简体中文' },
  { code: Languages.ChineseTraditional, label: '繁體中文' },
  { code: Languages.Korean, label: '한국어' },
  { code: Languages.Czech, label: 'Česky' },
  { code: Languages.Portuguese, label: 'Português' },
  { code: Languages.Thai, label: 'ไทย' },
];

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../config/store/rootReducer';
import useGetCurrentLanguage from './useGetCurrentLanguage';
import { setCurrentLanguage, setLanguages } from '../store/actions';
import { Languages } from '../interfaces/Languages';

/**
 * Initialize the language (in the Redux store) based on the booking's event configuration
 */
function useInitializeLanguage() {
  const dispatch = useDispatch();
  const language = useGetCurrentLanguage();
  const eventLanguage = useSelector((state: RootState) => state.booking.booking?.event.language);
  const bookingGuestLanguage = useSelector((state: RootState) => state.booking.guest?.language);
  const bookingLanguages = useSelector((state: RootState) => state.booking.booking?.languages);

  useEffect(() => {
    if (Array.isArray(bookingLanguages)) {
      dispatch(setLanguages(bookingLanguages as Languages[]));
    }

    if (
      !bookingGuestLanguage &&
      eventLanguage &&
      bookingLanguages?.includes(eventLanguage) &&
      Object.values<string>(Languages).includes(eventLanguage) &&
      eventLanguage !== language
    ) {
      dispatch(setCurrentLanguage(eventLanguage as Languages));
    } else if (bookingLanguages && !bookingLanguages?.includes(language)) {
      dispatch(setCurrentLanguage(bookingLanguages[0] as Languages));
    }
  }, [eventLanguage, bookingGuestLanguage]);
}

export default useInitializeLanguage;

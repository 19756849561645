import {
  BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS,
  BOOKING_REGISTRATION_SET_FORMDATA,
  BOOKING_REGISTRATION_SET_TIMESLOTS,
  BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION,
  BOOKING_SET_TARGET,
  BOOKING_SET_TARGET_ACCESS_PASSWORD,
  BOOKING_SET_TIMESLOT_TO_EDIT,
  BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY,
  BookingActionTypes,
  BookingState,
  REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED,
  REQUEST_BOOKING_CHANGE,
  REQUEST_BOOKING_CHANGE_FINISHED,
  REQUEST_BOOKING_REGISTER,
  REQUEST_BOOKING_REGISTER_FINISHED,
  REQUEST_CANCEL_TIME_SLOT_BOOKING,
  REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED,
  REQUEST_CHECK_TARGET_PASSWORD,
  REQUEST_CHECK_TARGET_PASSWORD_FINISHED,
  REQUEST_DELETE_GUEST_ACCOUNT_FINISHED,
  REQUEST_FETCH_ACCESS_METHOD_FINISHED,
  REQUEST_FETCH_AVAILABILITIES_FINISHED,
  REQUEST_FETCH_BOOKING_FINISHED,
  REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED,
  REQUEST_FETCH_GUEST_FINISHED,
  REQUEST_FETCH_MASTERDATA_FINISHED,
  REQUEST_FETCH_RSVP_TIMESLOT_FINISHED,
  REQUEST_FETCH_TIME_SLOT_ICS,
  REQUEST_FETCH_TIME_SLOT_ICS_FINISHED,
  REQUEST_FETCH_TIMESLOTS_FINISHED,
  REQUEST_FETCH_WALLET_PASS,
  REQUEST_FETCH_WALLET_PASS_FINISHED,
  RESET_ALL,
  RESET_REGISTRATION,
  RESET_TIME_SLOT_ICS,
  RESET_WALLET_PASS,
} from './types';
import Guest from '../interfaces/Guest';
import Booking from '../interfaces/Booking';
import TimeSlot, { TimeSlotObjectMap } from '../interfaces/TimeSlot';
import MasterData from '../interfaces/MasterData';
import { BookingLanguageResponse, RegisterResponse } from './actions';
import { AccessMethod } from '../interfaces/AccessMethod';
import WalletPass from '../interfaces/WalletPass';

export const initialState: BookingState = {
  hasAvailabilities: null,
  booking: null,
  timeSlots: {},
  rsvpTimeslot: null,
  guest: null,
  registration: {
    timeSlots: [],
    formData: {
      title: '',
      firstName: '',
      lastName: '',
      email: '',
      guests: [],
      customFields: {},
      privacyNoticeChecked: false,
    },
    additionalGuests: [],
    confirmed: false,
    changeConfirmed: false,
    createdGuestUuid: '',
    deletedGuestUuid: '',
  },
  captchaValidated: false,
  currentTarget: '',
  timeSlotToEdit: null,
  timeSlotCancelled: false,
  ics: '',
  walletPass: {
    file: undefined,
    url: '',
  },
  walletPassDialogVisible: false,
  walletPassDialogSelection: null,
  masterData: {
    countries: [],
    languages: [],
    titles: [],
  },
  accessMethod: null,
  accessPassword: '',
  accessPasswordValid: null,
  emailVerification: {
    email: '',
    duplicate: false,
    verified: true,
  },
};

function reducer(state = initialState, action: BookingActionTypes): BookingState {
  switch (action.type) {
    case REQUEST_FETCH_BOOKING_FINISHED:
      return {
        ...state,
        booking: action.payload && !action.error ? (action.payload as Booking) : initialState.booking,
      };
    case REQUEST_FETCH_BOOKING_LANGUAGE_FINISHED: {
      if (!action?.payload || action.error) {
        return { ...state };
      }
      const payload = action.payload as BookingLanguageResponse;
      return {
        ...state,
        booking: (state.booking
          ? {
              ...state.booking,
              event: state.booking.event
                ? {
                    ...state.booking.event,
                    language: payload.masterEventLanguage,
                  }
                : {
                    language: payload.masterEventLanguage,
                  },
              languages: payload.bookingLanguages,
            }
          : {
              event: {
                language: payload.masterEventLanguage,
              },
              languages: payload.bookingLanguages,
            }) as Booking,
      };
    }
    case REQUEST_FETCH_TIMESLOTS_FINISHED:
      return {
        ...state,
        timeSlots: action.payload && !action.error ? (action.payload as TimeSlotObjectMap) : initialState.timeSlots,
      };
    case REQUEST_FETCH_RSVP_TIMESLOT_FINISHED:
      return {
        ...state,
        rsvpTimeslot: action.payload && !action.error ? (action.payload as TimeSlot) : initialState.rsvpTimeslot,
      };
    case REQUEST_FETCH_AVAILABILITIES_FINISHED: {
      if (action.error) {
        return {
          ...state,
          hasAvailabilities: initialState.hasAvailabilities,
        };
      }

      return {
        ...state,
        hasAvailabilities: action.payload === true,
      };
    }
    case REQUEST_FETCH_GUEST_FINISHED:
      return {
        ...state,
        guest: action.payload && !action.error ? (action.payload as Guest) : initialState.guest,
      };
    case REQUEST_BOOKING_REGISTER:
      return {
        ...state,
        registration: {
          ...state.registration,
          confirmed: false,
        },
      };
    case REQUEST_BOOKING_REGISTER_FINISHED:
      return {
        ...state,
        registration: {
          ...state.registration,
          confirmed: action.payload && !action.error ? true : initialState.registration.confirmed,
          changeConfirmed: false,
          createdGuestUuid:
            action.payload && !action.error
              ? (action.payload as RegisterResponse).guestUuid
              : initialState.registration.createdGuestUuid,
        },
      };
    case REQUEST_BOOKING_CHANGE:
      return {
        ...state,
        registration: {
          ...state.registration,
          changeConfirmed: false,
        },
      };
    case REQUEST_BOOKING_CHANGE_FINISHED:
      return {
        ...state,
        registration: {
          ...state.registration,
          confirmed: false,
          changeConfirmed: action.payload && !action.error ? true : initialState.registration.changeConfirmed,
        },
      };
    case REQUEST_BOOKING_CAPTCHA_VALIDATION_FINISHED:
      return {
        ...state,
        captchaValidated: action.payload && !action.error ? action.payload : initialState.captchaValidated,
      };
    case REQUEST_CANCEL_TIME_SLOT_BOOKING:
      return {
        ...state,
        timeSlotCancelled: false,
      };
    case REQUEST_CANCEL_TIME_SLOT_BOOKING_FINISHED: {
      if (action.error) {
        return state;
      }
      return {
        ...state,
        guest: action.payload ? (action.payload as Guest) : initialState.guest,
        timeSlotCancelled: true,
      };
    }
    case REQUEST_FETCH_TIME_SLOT_ICS:
    case RESET_TIME_SLOT_ICS:
      return {
        ...state,
        ics: initialState.ics,
      };
    case REQUEST_FETCH_TIME_SLOT_ICS_FINISHED:
      return {
        ...state,
        ics: action.payload && !action.error ? (action.payload as string) : initialState.ics,
      };
    case REQUEST_FETCH_WALLET_PASS:
      return {
        ...state,
        walletPass: initialState.walletPass,
      };
    case RESET_WALLET_PASS:
      return {
        ...state,
        walletPass: initialState.walletPass,
        walletPassDialogVisible: initialState.walletPassDialogVisible,
        walletPassDialogSelection: initialState.walletPassDialogSelection,
      };
    case REQUEST_FETCH_WALLET_PASS_FINISHED:
      return {
        ...state,
        walletPass: action.payload && !action.error ? (action.payload as WalletPass) : initialState.walletPass,
      };
    case BOOKING_SET_WALLET_PASS_DIALOG_VISIBILITY:
      return {
        ...state,
        walletPassDialogVisible: action.payload.isVisible,
        walletPassDialogSelection: action.payload?.selection ?? initialState.walletPassDialogSelection,
      };
    case BOOKING_SET_EMAIL_DUPLICATE_VERIFICATION:
      return {
        ...state,
        emailVerification: action.payload,
      };
    case REQUEST_FETCH_MASTERDATA_FINISHED:
      return {
        ...state,
        masterData: action.payload && !action.error ? (action.payload as MasterData) : initialState.masterData,
      };
    case REQUEST_FETCH_ACCESS_METHOD_FINISHED:
      return {
        ...state,
        accessMethod: action.payload && !action.error ? (action.payload as AccessMethod) : initialState.accessMethod,
      };
    case BOOKING_REGISTRATION_SET_TIMESLOTS:
      return {
        ...state,
        registration: {
          ...state.registration,
          timeSlots: action.payload,
        },
      };
    case BOOKING_REGISTRATION_SET_FORMDATA:
      return {
        ...state,
        registration: {
          ...state.registration,
          formData: action.payload,
        },
      };
    case BOOKING_REGISTRATION_SET_ADDITIONAL_GUESTS:
      return {
        ...state,
        registration: {
          ...state.registration,
          additionalGuests: action.payload,
        },
      };
    case BOOKING_SET_TARGET:
      return {
        ...state,
        currentTarget: action.payload,
      };
    case BOOKING_SET_TARGET_ACCESS_PASSWORD:
      return {
        ...state,
        accessPassword: action.payload,
      };
    case REQUEST_CHECK_TARGET_PASSWORD:
      return {
        ...state,
        accessPassword: '',
        accessPasswordValid: null,
      };
    case REQUEST_CHECK_TARGET_PASSWORD_FINISHED:
      return {
        ...state,
        accessPasswordValid: typeof action.payload !== 'boolean' || action.error ? false : (action.payload as boolean),
      };
    case BOOKING_SET_TIMESLOT_TO_EDIT:
      return {
        ...state,
        timeSlotToEdit: action.payload ?? null,
      };
    case RESET_REGISTRATION:
      return {
        ...state,
        registration: {
          ...state.registration,
          ...(action.payload.clearFormData && {
            formData: initialState.registration.formData,
            additionalGuests: initialState.registration.additionalGuests,
          }),
          ...(!action.payload.clearFormData && {
            confirmed: initialState.registration.confirmed,
            changeConfirmed: initialState.registration.changeConfirmed,
            timeSlots: initialState.registration.timeSlots,
            createdGuestUuid: initialState.registration.createdGuestUuid,
            deletedGuestUuid: initialState.registration.deletedGuestUuid,
          }),
        },
      };
    case REQUEST_DELETE_GUEST_ACCOUNT_FINISHED:
      return {
        ...state,
        registration: {
          ...state.registration,
          deletedGuestUuid:
            action.payload && !action.error ? (action.payload as string) : initialState.registration.deletedGuestUuid,
        },
      };
    case RESET_ALL:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

export default reducer;
